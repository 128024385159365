.menu {
  width: 340px;
  padding: 32px 0 0 26px;
  min-width: 360px;
  user-select: none;
  margin-bottom: 30px;
}
.menuTitle {
  display: flex;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  align-items: center;
}
.wrapper {
  display: flex;
}
.main {
  padding: 32px;
  width: 100%;
  position: relative;
}
.title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}
.accordion {
  width: 242px;
  height: 0;
  transform-origin: top center;
  transform: scaleY(0);
  display: flex;
  flex-direction: column;
  z-index: 3333;
  transition: .1s;
  opacity: 0;
  :global(.ant-pagination-jump-next) {
    margin-right: 0;
  }
  :global(.ant-pagination-jump-prev) {
    margin-right: 0;
  }
}

.active {
  transform: scaleY(1);
  height: auto;
  opacity: 1;
}
.topWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 20px;
}
.topItem {
  width: 190px;
  color: #ea5b0c;
  padding: 10px 18px;
  border: 1px solid #E8EDF0;
  font-size: 16px;
  font-weight: 500;
  line-clamp: 2;
  display: flex;
  align-items: center;
}
.productItem {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.link {
  border: 1px solid #E8EDF0;
  padding: 18px;
  margin-right: 26px;
  margin-bottom: 24px;
}
.img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}
.wrapperTitle {
  text-align: center;
}
.name {
  font-size: 16px;
  font-weight: 500;
  margin-top: 5px;
}
.city {
  color: #474747;
}
.invisible {
  opacity: 0;
}
.visible {
  opacity: 1;
  transition: .3s;
}
.companyItem {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
