@import "Styles/variables.less";
.container {
  overflow: auto;
  :global {
    .ant-input::placeholder,
    .ant-select-selection-placeholder,
    .ant-picker-input > input::placeholder,
    .ant-input[disabled] {
      color: grey !important;
      font-weight: 300;
    }
    .ant-form-item {
      /* Default styles for the form item */

      &.ant-form-item-has-error {
        textarea {
          &:hover,
          &:focus {
            box-shadow: none !important;
            border-color: transparent;
            .TextArea {
              border-right-width: 0px !important;
            }
          }
        }
      }

      &:not(.ant-form-item-has-error) {
        textarea {
          &:focus,
          &:hover {
            box-shadow: none !important;
            border-right-width: 0px !important;
          }
        }
      }
    }
  }
  .tableRow {
    .selector {
      :global {
        .ant-select-selector,
        .ant-select-selection-search-input {
          background-color: #fff !important;
        }
      }
    }
    .tableBody {
      .timeInput {
        border: none;
        background-color: transparent !important;
        width: 76px;
        padding: 0;
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
}
.link {
  color: @orange;
}
.error {
  color: #ff4d4f !important;
}
.datePicker.datePicker {
  background: white !important;
  background-color: white !important;
  :global {
    input {
      background: white !important;
      background-color: white !important;
      border: none !important;
    }
    .ant-picker.ant-picker-disabled {
      background: white !important;
      background-color: white !important;
    }
  }
}
.tableRow {
  width: max-content;
  display: flex;
  flex-direction: column;
  border: 1px solid #e8edf0;
  border-right: none !important;
}
.head {
  :global {
    .ant-table-column-sorter-inner {
      cursor: pointer;
    }
  }
  flex-shrink: 0;
  display: flex;
  background: #f5f7f8;
  .num {
    font-size: 18px;
  }
  .cell {
    align-items: center;
  }
}
.cell {
  padding: 5px 16px;
  color: #474747;
  font-weight: 600;
  display: flex;
  flex-shrink: 0;
  background: #f5f7f8;
  white-space: break-spaces;
  transition: 0.3s;
  justify-content: space-between;
  gap: 15px;
}
.companies {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  gap: 5px;

  & > p {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.cellContent {
  position: relative;
  display: flex;
  min-height: 32px;
}
.cell2 {
  padding: 24px 16px;
  flex-shrink: 0;
  transition: 0.3s;
  word-break: break-all;
  white-space: break-spaces;

  :global {
    .ant-form-item-control-input {
      align-items: normal;
    }
  }
  &.grey {
    background: #f2f2f2;
  }
  & > a {
    &:hover,
    :focus {
      color: @orange;
    }
  }
}
.tableBody {
  display: flex;
}
.stand {
  display: flex;
  .standLeft {
    width: max-content;
    display: flex;
  }
  .standRight {
    width: 1281px;
    min-height: 100%;
    flex-shrink: 0;
    flex-grow: 1;
    border: 1px solid #e8edf0;
    border-right: none;
    border-bottom: none;
    display: flex;
    .standDescription {
      padding: 24px 10px 0px 16px;
    }
  }
}
.controls {
  border-left: 1px solid #e8edf0;
  display: flex;
  flex-direction: column;
  .checkboxes {
    padding: 24px 16px;
  }
  .actions {
    border-top: 1px solid #e8edf0;
    .wrap {
      margin: 20px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
    .save,
    .moderate {
      display: flex !important;
      align-items: center;
      justify-content: center;
      padding: 4px 8px;
      font-weight: 500;
      width: 29px;
      max-height: 30px;
      border: none;
      outline: none;

      cursor: pointer;

      & > button {
        border: none;
        outline: none;
        background: none;
        font-weight: 500;
      }
    }
    .save {
      background: #f5f7f8;
      outline: none;
      border: none;
    }
    .moderate {
      background: #ffd5bd;
    }
  }
}
.small {
  width: 48px;
}
.premid {
  width: 110px;
}
.mid {
  width: 120px;
}
.big {
  width: 160px;
  & > a {
    word-break: break-word;
  }
}
.xl {
  width: 300px;
}
.disabled {
  cursor: not-allowed !important;
  user-select: none !important;
}

.textInput.textInput {
  width: 100%;
  height: 100%;
  background: white;
  background-color: white !important;
  border: none;
  resize: none;
  padding: 0;
}

.customPlaceholder {
  position: relative;
  z-index: -1;
  top: -24px;
}

.uploadText {
  cursor: pointer;
  color: #b0aeb4;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.35px;
  text-decoration-line: underline;
}

.fileInput {
  visibility: hidden;
}

.tableBody .textArea,
.stand .textArea,
.tableBody .textArea,
.stand .textArea textarea {
  max-height: initial;
  padding: 0;
  border: 0;
  background-color: transparent !important;
  &::placeholder {
    font-weight: 300;
  }

  &.greenPlaceholder {
    &::placeholder {
      color: #249f5d;
    }
  }
}
.deleteText {
  color: @orange;
  text-decoration: underline;
  cursor: pointer;
}
