@import "Styles/variables";
.block {
  &:not(:first-child) {
    margin-top: 18px;
  }
  background: @grey4;
  padding: 10px 10px 10px 18px;
  font-size: 1rem;
  .title {
    font-weight: 300;
    color: @grey;
  }
  .body {
    color: @grey7;
  }
}
