@import "../../styles/variables.less";

.container {
  position: relative;
  width: 100%;
  max-width: 1304px;
  background: #fff;
  margin: 0 auto 50px;

  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
}

.page_content {
  padding: 32px 0 60px;

  :global {
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none;
    }

    .ant-table-thead > tr > .ant-table-cell {
      background: #f5f7f8;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background: #fff !important;
    }
  }
}

.blockWithExhibitionTable {
  display: flex;
  flex-direction: column;
  margin-top: 48px;

  .titleContainer {
    margin-bottom: 16px;
  }

  .tableHeaderExhibition {
    display: grid;
    grid-template-columns: 370px 676px 168px;
    gap: 20px;
    padding: 16px 0;
    align-items: flex-start;
  }

  .textContainer {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.41px;
  }

  .text {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
  }

  .orange {
    color: @orange;
  }
}

.page_title {
  letter-spacing: 0.6px;
  font-size: 24px;
  font-weight: 500 !important;
  line-height: 28px;
  color: @general;
  margin-bottom: 25px;

  position: relative;
}

.hover_trigger:hover + .hover_text {
  display: block;
}

.hover_text {
  position: absolute;
  bottom: 0;
  left: 0;
  display: none;
  transform: translate(20%, 100%);
  z-index: 100;
  width: 500px;
  background: #fff;
  padding: 20px 15px;
  box-shadow: 0px 0px 16px #ccc;

  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

.time_cell {
  p {
    display: none;
  }
}

.page_title-link {
  color: @orange;
  margin-left: 5px;

  &__img {
    max-width: 100px;
    margin-left: 10px;
  }
}

.table_link {
  color: @orange;
  text-decoration: underline;
}

.company_table {
  margin-bottom: 32px;
}

.page_info {
  margin-bottom: 32px;
}

.info__text {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin-top: -5px;

  p {
    margin-top: 9px;
  }
}

.page_data {
  :global {
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none;
    }

    .ant-table-thead > tr > .ant-table-cell {
      border-bottom: none;
      background: #f5f7f8;
      font-size: 12px;
      color: #474747;
      padding-left: 16px;
      padding-right: 2px;

      @media screen and (max-width: 1200px) {
        font-size: 11px;
        line-height: 12px;
      }
    }

    .ant-table-tbody > tr > .ant-table-cell {
      font-size: 12px;
      padding-left: 5px;
      padding-right: 5px;
      text-align: center;
      color: @orange;
      cursor: pointer;
      line-height: 14px;
      transition: all 0.2s linear;

      &:hover {
        p {
          display: block;
          margin-bottom: 2px;
        }
      }
    }

    .ant-table-tbody > .ant-table-row > .ant-table-cell:hover {
      color: @general;
    }

    .ant-table-tbody::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    .ant-table-tbody::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    .ant-table-tbody::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    .ant-table-tbody::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > .ant-table-row > td.ant-table-cell {
      font-size: 12px;

      @media screen and (max-width: 1200px) {
        font-size: 10px;
        line-height: 12px;
      }
    }

    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background: @grey4;
    }
  }
}

.page_data__title {
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 28.13px !important;
  margin-bottom: 0 !important;
}

.hidden_cap {
  opacity: 0;
  position: absolute;
  z-index: -10000;
  left: -100px;
}

.table_time {
  display: block;
  color: @general;
  padding-left: 11px;
  text-align: left;
  cursor: text;
}

.busy_day {
  pointer-events: none !important;
  color: @grey !important;
  cursor: text;
}

.modal {
  max-width: 740px !important;
  width: 100% !important;

  :global {
    p {
      text-align: left !important;
    }

    .ant-modal-content {
      max-width: 740px;
      width: 100%;
    }

    .ant-modal-title {
      font-size: 20px;
      font-weight: 600;
      color: @general;
    }

    .ant-modal-body {
      padding-top: 0px;
    }

    .ant-modal-header {
      border-bottom: none;
      padding-top: 30px;
      padding-bottom: 24px;
    }

    .ant-modal-close-x {
      padding-top: 10px;
    }

    .ant-form-item-explain-error {
      font-weight: 400 !important;
    }
  }

  .modal__form {
    .form__input {
      background: @grey4 !important;
      border-radius: 0px;

      &:hover,
      &:focus {
        box-shadow: none !important;
        border: 1px solid #b0aeb4 !important;
      }
    }

    .form__input_textarea {
      min-height: 136px !important;
      padding: 12px !important;
    }

    .form__input_select {
      max-width: 290px;

      :global {
        .ant-select-selector {
          background: @grey4;
          max-width: 290px;
          height: auto !important;

          &:hover,
          &:focus {
            box-shadow: none !important;
            border: 1px solid #b0aeb4 !important;
          }

          .ant-select-selection-item {
            padding: 12px 10px;
            line-height: 17px !important;
            color: #000;
            font-weight: 300;
            letter-spacing: 0.35px;
          }
        }
      }
    }

    .form__input::-webkit-outer-spin-button,
    .form__input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      /* <-- Apparently some margin are still there even though it's hidden */
    }

    .form__input::-webkit-resizer {
      border: 8px solid transparent;
      border-radius: 4px;
      border-right: 8px solid #d9dfe3;
      border-bottom: 8px solid #d9dfe3;
    }

    .form__input[type="number"] {
      -moz-appearance: textfield;
      /* Firefox */
    }

    .form__top {
      display: flex;
      justify-content: space-between;

      .top__form_item {
        flex: 0 1 45%;
        display: block;
        margin-bottom: 18px;
      }
    }

    .form_item {
      display: block;
      margin-bottom: 18px;
    }

    .form__captcha {
      border: 1px solid #e8edf0;

      div {
        height: 40px;
        display: flex;
        align-items: center;
      }
    }
  }

  .modal_btn {
    margin-top: 40px;
  }

  .label {
    white-space: normal;
    line-height: 16px;
  }
}

.moderation_circle {
  display: inline-block;
  margin-bottom: 9px !important;
  transform: scale(0.7);
  margin-left: -4px;
}

.form_item__check {
  max-height: 40px;
}

.modal_info {
  .info_text {
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 18px;

    a {
      font-weight: bold;

      img {
        margin-left: 15px;
        width: 26px !important;
      }
    }
  }
}

.success_modal_block {
  margin-bottom: 20px;

  .block_bold {
    font-weight: 600 !important;

    span {
      display: block;
      color: #ea5b0c;
      margin-top: -2px;
    }
  }

  p {
    margin-top: 10px !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 25px;
  }
}

.info_table {
  border: 1px solid #b0aeb4;
  padding: 13px 17px;
  padding-bottom: 3px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;

  .table_cell {
    flex: 0 1 40%;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    div {
      margin-bottom: 10px;
    }

    &_sec {
      flex: 0 1 20%;
      font-size: 14px;
      line-height: 16px;
      margin-top: auto;

      div {
        padding: 4px 0px;
        margin-top: 1px;
        color: #ea5b0c;
      }
    }

    &_last {
      flex: 0 1 40%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      font-size: 14px;
      text-align: center;
      color: #222222;

      span {
        font-weight: 700;
        font-size: 16px;
        color: #ea5b0c;
      }
    }
  }
}

.wrapper_32 {
  padding: 0 32px;
}

.wrapper_24 {
  padding: 0 24px;
}

.block_with_calendar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  min-height: 50px;

  .subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
  }
}

.lower_table_meetings {
  display: flex;
  flex-direction: column;

  .tableHeaderExhibition {
    display: flex;
    justify-content: space-between;
    padding-top: 24px;
    padding-bottom: 18px;
  }
}

.meetingScheduleTableContainer {
  overflow-x: auto;
}
