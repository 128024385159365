.countrySelect.countrySelect {
  :global {
    .ant-select-selector.ant-select-selector.ant-select-selector {
      background-color: white !important;
      border-radius: 0 !important;
      background: white !important;
      align-items: center;
      &:hover,
      &:focus {
        box-shadow: none;
      }
      input {
        background-color: white !important;
        background: white !important;
      }
    }
  }
}

.textInput.textInput {
  width: 100%;
  height: 100px;
  background: white;
  background-color: white !important;
  border: none;
  resize: none;
  padding: 0;
  overflow: hidden;
}
