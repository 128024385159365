@import "Styles/variables.less";

.autoComplete {
  :global {
    .ant-select-focused .ant-select-selector,
    .ant-select-selector:focus,
    .ant-select-selector:active,
    .ant-select-open .ant-select-selector {
      border-color: #d9d9d9 !important;
      box-shadow: none !important;
    }
  }
  width: 100%;
  &.error {
    :global {
      .ant-select-selector.ant-select-selector.ant-select-selector {
        .ant-select-selection-placeholder {
          display: block;
          position: relative;
          text-overflow: break-word;
          text-wrap: wrap;
          min-width: 120px;
          line-height: unset;
          word-break: break-word;
          padding-right: unset;
          overflow: unset;
          color: #ff4d4f;
        }
      }
    }
  }
  :global {
    .ant-select-selector.ant-select-selector.ant-select-selector {
      background-color: transparent !important;
      background: transparent !important;
      padding: 0;
      border: none !important;

      & > .ant-select-selection-placeholder {
        display: none;
        position: relative;
        text-overflow: break-word;
        text-wrap: wrap;
        min-width: 120px;
        line-height: unset;
        word-break: break-word;
        padding-right: unset;
        overflow: unset;
        color: @green;
      }

      & > .ant-select-selection-search {
        width: 100%;
        left: 0;
        top: -5px;
      }

      input {
        background-color: transparent !important;
        background: transparent !important;
      }
    }
    .ant-select-arrow {
      display: none;
    }
  }
};

.textarea {
  resize: none;
  max-width: 128px !important;
  background-color: transparent !important;
  min-height: 120px !important;
}
