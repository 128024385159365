.wrapper {
  background-color: #fff;
  padding: 25px 32px;
  width: 1120px;
}

.title {
  font-size: 24px;
  color: #222222;
  font-weight: 500;
  margin-bottom: 20px;
}

.link {
  margin-left: 30px;
  cursor: pointer;
}

.wrapperPhoto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
}

.file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.imgWrapper {
  position: relative;
  display: flex;
  background-color: #f5f7f8;
  color: #478ecc;
}

.child {
  margin: 32px 0;
}

.logoTitle {
  margin-left: 30px;
  text-decoration: none;
  font-weight: 500;
  color: #000;
  margin-top: -10px;
}

.label {
  line-height: 1.2;
  font-weight: 500;

  &.req::after {
    content: "*";
  }
}

.keywordsDescription {
  font-size: 12px;
  font-weight: 300;
  color: #474747;
  margin-bottom: 32px;
}

.selectWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.select {
  border: 1px solid #e8edf0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.wid {
  width: 220px;
  height: 270px;
  min-width: 200px;
}
.selectFull {
  width: 100%;
  height: 100%;
}

.descriptionFile {
  margin: 12px 0;
  font-weight: 500;
  font-size: 14px;
}

.theTypeOfBusiness {
  color: #222222;
  font-weight: 500;
}

.btnWrapper {
  margin: 40px 0 10px 0;
  display: flex;
  justify-content: center;
}

.btn1 {
  max-width: 320px;
  white-space: normal;
}

.btn2 {
  background: #ea5b0c !important;
  font-weight: 400;
  font-size: 14px;
  color: #fff !important;
}

.btn3 {
  background: #d62d30;
  font-size: 14px;
  color: #fff;
  padding: 16px 10px;
  font-weight: 300;

  &:hover {
    background: transparent;
    border: 1px solid #343543;
    color: #d62d30;
  }
}

.txt {
  margin-bottom: 24px;
  display: flex;
  align-items: flex-start;
}

.btnWidth {
  min-width: 430px;
  margin-left: 10px;
}

.loader {
  position: absolute;
  right: 20px;
  top: 50px;
}
.modalP {
  margin-top: 14px;
  font-size: 17px;
}
.modalDiv {
  font-size: 18px;
  text-align: center;
  font-weight: 500;
}
.trash {
  margin-top: 13px;
  background-color: #f5f7f8;
  padding: 10px;
}
.line {
  width: 100%;
  height: 100%;
  border-top: 1px solid black;
  margin-top: 16px;
  &:hover {
    background-color: #fff;
  }
  &:before {
    content: "";
    position: absolute;
    width: auto;
    height: 1px;
    background-color: #000;
  }
}
.status {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  &__red {
    color: #f32d2d;
  }
  &__green {
    color: #249f5d;
  }
}
.changeForm {
  :global(.ant-form-item-label) {
    min-width: 200px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
  }

  :global(.ant-select-selector) {
    height: 100%;
    min-height: 40px;
  }

  :global(.ant-select-clear) {
    right: 25px;
  }

  :global(.ant-select-selection-item) {
    padding: 5px 0 !important;
  }

  :global(.ant-select-selection-placeholder) {
    padding: 5px 0 !important;
  }
}
.countrySelectWrapper {
  :global(.ant-select-selection-item) {
    padding: 0 !important;
  }

  :global(.ant-select-selection-placeholder) {
    padding: 0 !important;
  }
}

.group {
  :global(.ant-checkbox-group-item) {
    width: 32% !important;
    margin-bottom: 15px !important;
  }

  :global(.ant-checkbox + span) {
    align-self: center;
    margin-left: 10px;
  }
}

.companyKeywords {
  :global(.ant-form-item-explain-error) {
    max-width: 85%;
  }
}

@media screen and (max-width: 1320px) {
  .btnWidth {
    min-width: 50%;
    padding: 26px 8px !important;
  }
}

// TODO: Changing the global style of OPTGROUP from antDesign
