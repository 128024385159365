.multiSorterContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;

  &__sorter {
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }
}
