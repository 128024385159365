@import './variables';
a{
    color: @blackBlue;
}
a.blue{
    color: #478ECC;
}
a.orange{
    color: @orange;
    &_blue-hover:hover{
        color: #40a9ff;
    }
}
a.blue_hover:hover{
    color: #40a9ff;
}
