@import "../../styles/variables";

.wrapper {
  background: #fff;
  min-height: 100%;
  max-width: 95%;
  overflow-x: hidden !important;
  max-width: 1440px;
  .filter_table_container {
    width: calc(100%);
    overflow-x: auto;
  }

  .countWrapper {
    display: flex;
    flex-direction: column;
    .statistic {
      text-decoration: underline;
      color: @black;
      cursor: pointer;
    }
    .unproccessed {
      text-decoration: underline;
      color: @darkRose;
      cursor: pointer;
    }
  }

  .filter_table_search {
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .excel_btn {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    height: auto;
    border: 1px solid @blackBlue;
    cursor: pointer;
  }
  .search_btn {
    margin-top: 15px;
    width: 122px;
    height: 44px;
    border: none;
    background: @orange;
    padding: 0 40px;
    font-weight: 300;
    line-height: 16px;
    font-size: 14px;
    color: #fff;
    width: max-content;

    &:hover {
      border-color: @blackBlue;
      background: @blackBlue;
      color: #fff;
    }
    &:focus {
      color: #fff;
      background: @orange;
    }
  }
  .search {
    margin-top: 25px;
    display: flex;
    gap: 80px;
    align-items: flex-end;
    width: 90%;
    @media screen and (max-width: 1280px) {
      flex-direction: column;
      width: 400px;
      gap: 10px;
    }
    .input {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .requests_table__container {
    width: 100% !important;
  }

  .requests_table {
    margin-top: 35px;

    :global {
      .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        display: none;
      }

      .ant-table-thead > tr > .ant-table-cell {
        border-bottom: none;
        background: #f5f7f8;
        min-width: 120px !important;
        min-height: 60px !important;
        max-width: 300px !important;
        height: 60px !important;
        padding-right: 0;
        &:nth-child(2) {
          max-width: 150px !important;
        }
      }

      .ant-table-tbody > tr.ant-table-row > .ant-table-cell {
        &:first-child {
          background: #f5f7f8 !important;
          min-height: 60px !important;
          height: 60px !important;
          font-weight: 600;
          color: #474747;
          text-decoration: none;
          cursor: text;
          &:hover {
            color: #474747 !important;
          }
        }
        text-decoration: underline;
        cursor: pointer;

        span {
          display: block;
        }

        &:hover {
          color: @orange !important;
        }
      }

      .ant-table-tbody > tr.ant-table-row:hover > td,
      .ant-table-tbody > tr > td.ant-table-cell-row-hover {
        background: #fff !important;
      }
      .ant-table-tbody > tr.ant-table-row:hover > td,
      .ant-table-tbody > .ant-table-row:last-child > td.ant-table-cell {
        border-bottom: none !important;
      }
    }
  }
}

.contact_link {
  color: @orange;
  text-decoration: underline;
  display: block;
  margin-top: 10px;
  padding: 0px;
  text-align: left;
  background: #fff;
  border: none;
  cursor: pointer;
  &:hover {
    color: @orange;
    text-decoration: underline;
  }
}

.disabled {
  pointer-events: none;
  color: #DCDCDC;
  &:hover {
    color: #DCDCDC;
    text-decoration: underline
  }
}

.TextArea {
  background-color: #f5f7f8;
  min-height: 130px !important;
  textarea {
    min-height: 130px !important;
  }
}

.buttons {
  display: flex;
  gap: 24px;
}

.modal {
  width: 716px !important;
  :global {
    .ant-global-content {
      .ant-modal-body {
        padding: 0;
      }
    }
  }
}

.second_modal {
  width: 716px !important;
  section {
    display: flex;
  }
  :global {
    .ant-global-content {
      .ant-modal-body {
        padding: 0;
      }
    }
  }
}
