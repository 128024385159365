@import "Styles/variables.less";

.checkboxWrapper {
  display: flex;
  margin: 18px 0;
}

.check {
  width: 24px;
  height: 24px;
  background-color: #f5f7f8;
  margin-right: 12px;
  cursor: pointer;
  position: absolute;
  left: calc(50% - 12px);
  bottom: 5px;
}

.req::after {
  content: "*";
}
.captchaWrap {
  display: flex;
  input {
    width: 154px;
    &:hover,
    &:focus {
      border-color: @grey!important;
      box-shadow: none;
      outline: none;
    }
  }
  div {
    display: flex;
    align-items: center;
    min-width: none !important;
    width: none !important;
    max-width: none !important;
  }
  &__captcha {
    padding-right: 11px;
    display: flex;
  }
  &__wrap {
    border: 1px solid #e8edf0 !important;
    padding: 5px 0;
    height: 40px;
  }
  :global(.ant-form-item-explain-error) {
    position: absolute;
    left: 0;
  }
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.offerForm {
  input, textarea {
    background-color: #F5F7F8 !important;
  }

  :global {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      height: 40px !important;
      display: flex;
      align-items: center;
      background-color: #F5F7F8 !important;
    }

    .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
      .ant-select-selector {
      background-color: #fff !important;
    }
  }
}
.textarea {
  min-height: 120px !important;
}
