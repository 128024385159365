@import "../../styles/variables.less";

.footer {
  padding: 24px 0;
  margin-top: 40px;
  background-color: #fff;
  font-size: 1.14rem;
  margin-top: auto;
  .link,
  .bottom_link {
    &:hover {
      color: @orange;
    }
  }
  .link {
    font-size: 1.14rem;
    font-weight: 500;
  }
  .bottom_link {
    text-decoration: underline;
    font-size: 15px;
    &:hover {
      text-decoration: none;
    }
  }

  @media only screen and (max-width: 1230px) {
    .wrap {
      flex: 0 0 90%;
      width: 100%;
      max-width: 90%;
    }
  }
}
