.regular_block {
  :global {
    .ant-modal-wrap .ant-modal-body p {
      margin-top: 0px !important;
      text-align: left !important;
    }
  }
  background-color: #fff;
  padding: 32px 32px 25px 32px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
}
.modalWrapper {
  width: 680px !important;
}

.buttons {
  display: flex;
  gap: 48px;
  justify-content: center;
  margin-top: 24px;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 24px;
}
.title {
  color: #222;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.6px;
}
.modal_desc.modal_desc {
  color: #474747;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  letter-spacing: 0.45px;
  text-align: left !important;
  margin-top: 0 !important;
}

.exhibitionsLengthInfo {
  background-color: #f5f7f8;
  padding: 5px;
}

.modal_title {
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.45px;
  margin-bottom: 24px;
}
