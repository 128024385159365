.imgWrapper {
  width: 100%;
}

.index {
  color: #474747;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}

.title {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
}

.table {
  width: 358px;
}

.tableWrapper {
  display: flex;
  width: 100%;
  max-width: 1120px;
}

.expand {
  text-decoration-line: underline;
  cursor: pointer;

  &:hover {
    color: #1890ff;
  }
}

.btn {
  border: 2px solid #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 120px;
  padding: 16px;
  text-align: center;

  .btnText {
    cursor: pointer;
    color: #ea5b0c;
    white-space: wrap;
    text-decoration-line: underline;

    &:hover {
      color: #1890ff;
    }
  }
}

.btnWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.linkExhibitionName {
  &:hover {
    color: #1890ff !important;
  }
}

.descr {
  border-top: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  width: calc(100% - 358px);
  padding: 24px 16px;
}

.main {
  border: 2px solid #f0f0f0;
  border-right: none;

  :global(.ant-table-tbody > tr > td) {
    border: none;
  }

  :global(.ant-table-thead > tr > th) {
    border: none;
  }

  :global(.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before) {
    display: none;
  }
}

.section {
  overflow: auto;

  :global {
    .ant-table-pagination.ant-pagination {
      display: none;
    }
  }
}

.modal {
  :global(.ant-modal-body) {
    justify-content: center !important;
    width: max-content;
  }

  :global(.ant-modal-content) {
    width: max-content;
  }

  :global(.ant-modal) {
    display: flex !important;
    justify-content: center !important;
  }
}

.full {
  color: #ea5b0c;
  font-size: 18px;
  font-weight: 400;
  text-decoration-line: underline;
  margin: 25px 0;
  cursor: pointer;

  &:hover {
    color: #1890ff;
  }
}