@import "Styles/variables";

.section {
  font-weight: 300;

  :global {
    .ant-table-wrapper{
      margin-top: 0px !important;
    }
    .ant-table {
      width: fit-content;
      border: @accountBorder;
      border-bottom: none;
    }
  }
}

.text {
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
}

.title {
  composes: text;
  letter-spacing: 0.6px;
  margin-bottom: 18px;
}

.controlBtn {
  &:global(.ant-btn) {
    padding: 0 40px;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.35px;
    font-weight: 300;
    background: @blackBlue;

    &:hover,
    &:focus {
      background: @blackBlue;
      border-color: @blackBlue;
      color: #fff;
    }
  }
}

.qmodal{
  :global{
    .ant-modal-header{
      border-bottom: 0px !important;
      padding-bottom: 0px;
      padding-top: 20px;
    }
    .ant-modal-body{
      padding-top: 0;
    }
    .ant-modal-footer{
      padding-top: 0;
      padding-bottom: 20px;
      display: flex;
      gap: 90px;

      button{
        padding-top: 10px !important;
        padding-bottom: 10px !important;
      }

    }

    .btn{
      font-size: 16px !important;
      padding-top: 14px !important;
      padding-bottom: 14px !important;
      height: auto !important;
      margin: 0 !important;
    }

    .btn_v8{
      font-weight: 600 !important;
    }
  }

  .qmodal_title{
    font-size: 18px;
    font-weight: 600;
  }

  .qmodal_text{
    min-width: 400px;
    margin-top: 27px !important;
    color: #474747 !important;
    font-size: 16px !important;
    line-height: 21px !important;
    letter-spacing: 0.45px !important;
    font-weight: 400 !important;
    text-align: center;

    max-width: 350px;
    margin-left: auto;
    margin-right: auto;

    span{
      display: block;
      margin-top: 8px;
      margin-bottom: -10px;
    }
  }
}
