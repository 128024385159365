.preview {
  font-size: 18px;
  .flex {
    display: flex;
    .left {
      width: 200px;
    }
    & p {
      margin-top: 0px !important;
    }
  }
  .descriptionTitle {
    font-weight: 500;
    margin-top: 0px !important;
  }
  .description {
    font-size: 16px;
  }
}
