@import "Styles/variables";

.section {
  composes: section from "Pages/FS16-IncomingRequestsSeller/IncomingRequestsSeller.module.less";
}

.scrollable {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}

.download {
  composes: btn from "Components/Dialogs/DialogsSearch.module.less";

  &:global(.ant-btn) {
    width: fit-content;
    padding: 0 38px;
    margin-bottom: 18px;
    background: @blackBlue;

    &:hover,
    &:focus {
      background: @blackBlue;
      border-color: @blackBlue;
    }
  }
}
