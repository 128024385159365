.formItem {
  &:global(.ant-row.ant-form-item) {
    label {
      height: 100%;
      magrin-left: auto;
      align-items: flex-start;
      margin-top: 10px;
    }
  }
}
.videoContainer {
  iframe {
    width: 100%;
    height: 100%;
  }
}
.paymentRow {
  :global(.ant-form-item) {
    margin: 0 !important;
  }
}

.bigLabel {
  gap: 30px !important;

  label {
    width: 360px;
  }

  @media only screen and (max-width: 1338px) {
    gap: 50px !important;
  }
}

.close {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1;
  cursor: pointer;
}

.margin {
  margin-bottom: 18px !important;
}
.trash {
  margin-top: 13px;
  background-color: #f5f7f8;
  padding: 10px;
}
