@import "Styles/variables";

.section {
  max-width: 2170px;
  display: flex;
  flex-direction: column;
  background: #fff;
  gap: 20px;
  padding: 32px 32px 30px;
  &_top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 50px;
  }

  .tooltip {
    font-size: 14px;
    font-weight: 300;
    margin-top: 24px;
    margin-bottom: 10px;
  }
}

.scrollable {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  height: 60vh;
  gap: 50px;
}
