@import "Styles/variables";

.title {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 24px;
  line-height: 28px;
  letter-spacing: 0.6px;
}

.subtitle {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.6px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.fileContainer {
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  width: 260px;
  height: 60px;
  background: @grey4;
}

.loading {
  position: absolute;
  bottom: 10px;
  right: 20px;
  color: #ff0000;
}

.loadingSuccess {
  color: #008000;
}

.imgContainer {
  display: flex;
  align-items: center;
  gap: 50px;
  width: 480px;
  height: 85px;
  background: @grey4;
  position: relative;
}

.imgWrapper {
  position: relative;
  display: flex;
  background-color: #f5f7f8;
  color: #478ecc;
  width: 480px;
  height: 85px;
}

.imgWrapperWithCompanyPhoto {
  gap: 19px;
}

.imgContainerWithUserPhoto {
  gap: 69px;
}

.TextArea {
  min-height: 140px !important;
  overflow: hidden !important;

  textarea {
    min-height: 140px !important;
    background-color: #f5f7f8 !important;
    overflow: hidden !important;
  }
}

:global {
  .ant-form-item-has-error textarea,
  .ant-form-item-has-error:hover textarea,
  .ant-form-item-has-error textarea:focus {
    border-color: #ff4d4f;
  }
}

.uploader {
  cursor: pointer;
  font-weight: 300;
}

.additionalStuffUploadContainer {
  display: flex;
  justify-content: space-between;
}

.additionalStuffContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.additionalStuffAcceptFilesList {
  display: flex;
  flex-direction: column;
  gap: 25px;
  list-style: none;
  padding-left: 0px;
}

.file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.additionalStuffUploadButtonContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
}

.additionalStuffRecommendedImageSize {
  font-size: 11px;
  font-weight: 300;
  color: rgb(71, 71, 71);
}

.additionalStuffUploadButton {
  width: 500px;
  height: 85px;
  background-color: #f5f7f8;
}

.uploads {
  height: 100%;
  aspect-ratio: 2 / 1;
  width: 83px;
  object-fit: cover;
}

.passwordInput {
  :global {
    input.ant-input {
      background-color: rgb(245, 247, 248);
    }
  }
}

.stuffContainer {
  width: 480px;
  height: fit-content;
  padding: 16px 16px 24px;
  border: 1px solid @grey2;
}

.countrySelectWrapper {
  :global(.ant-select-selection-item) {
    padding: 0 !important;
  }

  :global(.ant-select-selection-placeholder) {
    padding: 0 !important;
  }

  :global(.ant-select-selector) {
    overflow-y: scroll;
  }
}

.line {
  width: 100%;
  height: 100%;
  border-top: 1px solid black;
  margin-top: 16px;
  &:hover {
    background-color: #fff;
  }
  &:before {
    content: "";
    position: absolute;
    width: auto;
    height: 1px;
    background-color: #000;
  }
}

.help {
  font-weight: 300;
  font-size: 12px;
  max-width: fit-content;
  color: @grey7;
}

.label_userType {
  font-size: 18px;
  font-weight: 500;
  margin-right: 20px;
}

.label_1 {
  width: 180px;
  font-weight: 500;
}

.label_2 {
  width: 220px;
  font-weight: 500;
}

.label_3 {
  width: 180px;
  font-weight: 500;
}

.label_4 {
  width: 120px;
  font-weight: 500;
}

.icon_pointer {
  pointer-events: none !important;
}
