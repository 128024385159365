@import "Styles/variables";

.section {
  composes: section from "Components/Dialogs/DialogsTable.module.less";
}

.link {
  composes: link from "Components/Dialogs/DialogsTable.module.less";
}

.active {
  color: @green;
}

.inactive {
  color: @grey;
}
