@import "Styles/variables.less";

.wrapper {
  overflow: auto;
  height: 100%;
  border: 2px solid @grey2;
  @media (min-width: 2543px) {
    min-width: min-content;
  }

  .scrollable {
    min-width: 1840px;
    .tableHead {
      display: flex;
      justify-content: space-between;
      &_item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &__top {
          display: flex;
          height: 124px;
          padding: 12px 12px 12px 16px;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          background: @grey4;
          p {
            width: 132px;
          }
        }
        &__bottom {
          display: flex;
          height: 64px;
          padding: 12px 12px 12px 16px;
          align-items: center;
          align-self: stretch;
        }
      }
    }

    .standsContent {
      display: flex;
      flex-direction: column;
      width: 1920px;

      &__item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-width: 185px;

        &_top {
          display: flex;
          height: 64px;
          padding: 12px 12px 12px 16px;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          background: @grey4;
          p {
            width: 132px;
          }
        }
        &_bottom {
          display: flex;
          height: 64px;
          padding: 12px 12px 12px 16px;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          & > img {
            margin: auto;
            max-width: 130px;
            max-height: 65px;
          }
        }
      }
      .textarea {
        width: 1120px;
        border-top: 1px solid #e8edf0;
        border-bottom: 1px solid #e8edf0;
        border-left: 1px solid #e8edf0;
        border-right: 1px solid #e8edf0;
        padding: 24px 24px 24px 16px;
        min-height: 100%;
        .textArea {
          background-color: white !important;
          background: white !important;
          border: none;
          outline: none;
          resize: none !important;
        }
      }
      .actions {
        border-top: 1px solid #e8edf0;

        &_btns {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          padding: 24px 24px 24px 16px;
          border-top: 1px solid @grey2;
          border-bottom: 1px solid @grey2;

          & > * {
            cursor: pointer;
          }

          .previewEdit,
          .editSave {
            display: flex;
            padding: 4px 10px;
            align-items: center;
            gap: 3px;
          }
          .previewEdit {
            background: @grey4;
          }
          .editSave {
            background: @lightPink;
          }
        }

        &_status {
          display: flex;
          flex-direction: column;
          gap: 24px;
          padding: 24px 24px 24px 16px;
          &__item {
            display: flex;
            align-items: center;
            gap: 10px;

            :global {
              .ant-checkbox-inner {
                max-width: 24px;
                max-height: 24px;
                &::after {
                  width: 9.714286px;
                  height: 14.142857px;
                }
              }
            }
          }
        }
      }
      .moderationStatus {
        width: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.amountCompany {
  line-height: 14px !important;
}
